/* CSS RESET */  
@font-face { 
  font-family: 'NanumSquare'; 
  font-weight: 400;
  font-style: normal;
  src: local('NanumSquare'),
  url(./fonts/NanumSquareR.woff) format('woff'); 
  font-display: swap;
}

@font-face { 
  font-family: 'NanumSquare'; 
  font-weight: 700;
  font-style: normal;
  src: local('NanumSquare'),
  url(./fonts/NanumSquareB.woff) format('woff'); 
  font-display: swap;
}

@font-face { 
  font-family: 'NanumSquare'; 
  font-weight: 800;
  font-style: normal;
  src: local('NanumSquare'),
  url(./fonts/NanumSquareEB.woff) format('woff'); 
  font-display: swap;
}


* {
  -webkit-text-size-adjust: none;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  padding: 0;
  border: 0;
  margin: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none;
}

a {
  text-decoration: none;
  outline: none;
  color: inherit;
}

ul,
li {
  list-style: none;
  margin: 0;
}

form {
  width: 100%
}

img {
  border: 0;
  object-fit: contain;
}

button{
  background-color: transparent;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  font: inherit;
}

input{
  text-decoration: none;
  background-color: none;
  outline: none;
  transition: all 0.1s linear;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-transition: background-color 9999s ease-out;
  transition: background-color 5000s ease-in-out 0s;
}

textarea {
  resize: none;
  border: none;
  outline: none;
}

select {
  background: none;
  outline: none;
  border-radius: 0;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
}

::-webkit-scrollbar { display: none; }

html, #root {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
} 

body {
  position: relative;
  font-family: 'Noto Sans CJK KR', 'Noto Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Common CSS Class - Size */
.FullWidth {
  width: 100%
}

/* Common CSS Class - View */
.CenterView {
  display: flex;
  align-items: center;
  justify-content: center;
}

.RowCenterView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.RowCenterView-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.RowCenterView-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.RowCenterView-space-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.RowCenterView-space-around {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.ColumnCenterView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ColumnStartView-space-between {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.ColumnStartView-space-around {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

/* Common CSS Class - Text */

.Text-CenterAlign {
  text-align: center;
}

.Text-PreWrap {
  white-space: pre-wrap;
}
